<tui-hosted-dropdown #dropdownComponent [content]="content">
  <button tuiButton appearance="mono" size="m" icon="tuiIconChevronDown">
    Разделы
  </button>
</tui-hosted-dropdown>
<ng-template #content>
  <tui-data-list role="menu">
    <tui-opt-group>
      @for (item of availableMenuItems(); track $index) {
        <button (click)="navigate(item); dropdownComponent.close()" tuiOption>
          {{ item.name }}
        </button>
      }
    </tui-opt-group>
  </tui-data-list>
</ng-template>
