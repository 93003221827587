import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import {
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { Store } from '@ngxs/store';
import { toSignal } from '@angular/core/rxjs-interop';
import { TuiAvatarModule } from '@taiga-ui/kit';
import { take } from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { AuthState } from '@store/auth/auth.state';
import { Logout } from '@store/auth/auth.actions';
import { RouteNames } from '../../../../route-names';

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [
    TuiDropdownModule,
    TuiAvatarModule,
    TuiDataListModule,
    TuiHostedDropdownModule,
    TuiSvgModule,
    RouterLink,
  ],
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  userState = toSignal(this.store.select(AuthState.getUser));

  userFullName = computed(
    () => `${this.userState()?.firstName} ${this.userState()?.lastName}`,
  );

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  protected logout() {
    this.store
      .dispatch(new Logout())
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigate([RouteNames.MAIN_PAGE]);
        },
      });
  }
}
