import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { map, Observable, of, tap } from 'rxjs';
import { RouteNames } from '../../route-names';

export const uniqueKeyGuard: CanActivateFn = (
  route,
  state,
): Observable<boolean> => {
  const uniqueKey: Nullable<string> = route.queryParams.uniqueKey;
  const auth = inject(AuthService);
  const router = inject(Router);
  if (!uniqueKey) {
    return of(false).pipe(
      tap(() => {
        router.navigate([RouteNames.MAIN_PAGE]);
      }),
    );
  }
  return auth.getInviteInfo(uniqueKey).pipe(
    map(invites => !!invites.length),
    tap(canActivate => {
      if (!canActivate) {
        router.navigate([RouteNames.MAIN_PAGE]);
      }
    }),
  );
};
