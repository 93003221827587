import { Injectable } from '@angular/core';
import { fromEvent, share } from 'rxjs';

interface EventData {
  type: string;
  payload: any;
}
export enum MessageTypes {
  NAVIGATE_LOGIN = 'NAVIGATE_LOGIN',
  NAVIGATE_KANBAN = 'NAVIGATE_KANBAN',
}
@Injectable({
  providedIn: 'root',
})
export class BroadcastChannelService {
  private readonly channel = new BroadcastChannel('main-channel');

  messages$ = fromEvent<MessageEvent<EventData>>(this.channel, 'message').pipe(
    share(),
  );

  postMessage(message: { type: MessageTypes; payload?: unknown }) {
    this.channel.postMessage(message);
  }
}
