import { Injectable } from '@angular/core';
import { TuiAlertService } from '@taiga-ui/core';
import { take } from 'rxjs';

const ERROR_HEADING = 'Ошибка';
const WARNING_HEADING = 'Внимание';
const INFO_HEADING = 'Информация';
const SUCCESS_HEADING = 'Успешно';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private readonly alertService: TuiAlertService) {}

  showError(message: string = '') {
    this.alertService
      .open(typeof message === 'object' ? 'Ошибка сервера' : message, {
        label: ERROR_HEADING,
        status: 'error',
        autoClose: true,
      })
      .pipe(take(1))
      .subscribe();
  }

  showSuccess(message: string = '') {
    this.alertService
      .open(message, {
        label: SUCCESS_HEADING,
        status: 'success',
        autoClose: true,
      })
      .pipe(take(1))
      .subscribe();
  }

  showWarning(message: string = '') {
    this.alertService
      .open(message, {
        label: WARNING_HEADING,
        status: 'warning',
        autoClose: true,
      })
      .pipe(take(1))
      .subscribe();
  }

  showInfo(message: string = '') {
    this.alertService
      .open(message, {
        label: INFO_HEADING,
        status: 'info',
        autoClose: true,
      })
      .pipe(take(1))
      .subscribe();
  }
}
