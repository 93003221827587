import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  TuiInputModule,
  TuiInputPasswordModule,
  TuiIslandModule,
} from '@taiga-ui/kit';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { passwordConfirmValidator } from '@shared/validators/password-confirm.validator';
import { catchError, EMPTY, take, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { AuthService } from '@shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';
import { RouteNames } from '../../../../route-names';

@Component({
  selector: 'app-user-reset-password-form',
  standalone: true,
  imports: [
    TuiIslandModule,
    ReactiveFormsModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiInputPasswordModule,
    TuiButtonModule,
    AsyncPipe,
  ],
  templateUrl: './user-reset-password-form.component.html',
  styleUrl: './user-reset-password-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserResetPasswordFormComponent implements OnInit {
  form = this.fb.group(
    {
      email: this.fb.nonNullable.control('', [
        Validators.required,
        Validators.email,
      ]),
      resetPasswordToken: this.fb.nonNullable.control(''),
      password: this.fb.nonNullable.control(''),
      passwordConfirm: this.fb.control(''),
    },
    {
      validators: [passwordConfirmValidator()],
    },
  );

  constructor(
    private readonly fb: FormBuilder,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly alert: AlertService,
  ) {}

  submit() {
    if (this.form.controls.resetPasswordToken.value) {
      const { passwordConfirm, ...payload } = this.form.getRawValue();
      this.auth
        .resetPassword(payload)
        .pipe(
          take(1),
          tap(() => {
            this.alert.showSuccess();
            this.router.navigate([RouteNames.LOGIN_PAGE]);
          }),
          catchError(err => {
            this.alert.showError(err.error);
            return EMPTY;
          }),
        )
        .subscribe();
    } else {
      this.auth
        .sendResetPasswordMessage(this.form.controls.email.getRawValue())
        .pipe(
          take(1),
          tap(() => {
            this.form.reset();
            this.alert.showSuccess();
          }),
          catchError(() => {
            this.alert.showError();
            return EMPTY;
          }),
        )
        .subscribe();
    }
  }

  ngOnInit() {
    const { email, token } = this.route.snapshot.queryParams;

    if (token && email) {
      this.form.patchValue({
        email,
        resetPasswordToken: token,
      });
      const controls = this.form.controls;
      controls.email.disable();
      controls.password.addValidators([Validators.required]);
      controls.passwordConfirm.addValidators([Validators.required]);
    }
  }
}
