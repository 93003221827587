import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { RouteNames } from '../../route-names';
import { AuthState } from '../../store/auth/auth.state';

export const isAuthenticatedGuard: CanActivateFn = () => {
  const router = inject(Router);
  const isAuthenticated = inject(Store).selectSnapshot(
    AuthState.isAuthenticated,
  );
  return isAuthenticated || router.navigate([RouteNames.LOGIN_PAGE]);
};
