import {
  OrganizationRouteNames,
  PaymentOrderRouteNames,
  RouteNames,
} from '../../route-names';
import { Roles } from '../../types/auth';

export type MenuItem = {
  name: string;
  authenticated: boolean;
  path: string;
  roles?: Roles[];
};
export const MENU_ITEMS: MenuItem[] = [
  {
    name: 'Журнал',
    authenticated: true,
    path: RouteNames.JOURNAL_PAGE,
  },
  {
    name: 'Канбан',
    authenticated: true,
    path: RouteNames.KANBAN_PAGE,
  },
];

export const DROPDOWN_MENU_ITEMS: MenuItem[] = [
  {
    name: 'Список компаний',
    roles: [Roles.SUPER_ADMIN],
    authenticated: true,
    path: OrganizationRouteNames.LIST,
  },
  {
    name: 'Платежные поручения',
    roles: [],
    authenticated: true,
    path: PaymentOrderRouteNames.LIST,
  },
  {
    name: 'Управление компанией',
    roles: [Roles.ADMIN],
    authenticated: true,
    path: OrganizationRouteNames.DETAILS,
  },
  {
    name: 'ТНВЭД',
    authenticated: true,
    path: RouteNames.NOMENCLATURE,
  },
  {
    name: 'Каталоги',
    authenticated: true,
    path: RouteNames.CATALOGS_PAGE,
  },
];
