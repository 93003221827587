import { Environment } from '../app/types/environment';

export const environment: Environment = {
  production: import.meta.env.NG_APP_PRODUCTION,
  apiUrl: import.meta.env.NG_APP_API_URL,
  webUrl: import.meta.env.NG_APP_WEB_URL,
  websocketUrl: import.meta.env.NG_APP_WEBSOCKET_URL,
  googleApiKey: import.meta.env.NG_APP_GOOGLE_API_KEY,
  googleAppId: import.meta.env.NG_APP_GOOGLE_APP_ID,
};
