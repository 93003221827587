import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordConfirmValidator(): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const password = group.get('password');
    const passwordConfirm = group.get('passwordConfirm');

    if (password?.value !== passwordConfirm?.value) {
      passwordConfirm?.setErrors(
        { passwordConfirmMatches: false },
        { emitEvent: false },
      );
    } else {
      passwordConfirm?.setErrors(null, { emitEvent: false });
    }
    return null;
  };
}
