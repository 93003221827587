import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserRegisterFormComponent } from '../../components/user-register-form/user-register-form.component';

@Component({
  selector: 'app-user-register',
  standalone: true,
  imports: [UserRegisterFormComponent],
  templateUrl: './user-register.component.html',
  styleUrl: './user-register.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRegisterComponent {}
