import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserResetPasswordFormComponent } from '../../components/user-reset-password-form/user-reset-password-form.component';

@Component({
  selector: 'app-user-reset-password',
  standalone: true,
  imports: [UserResetPasswordFormComponent],
  templateUrl: './user-reset-password.component.html',
  styleUrl: './user-reset-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserResetPasswordComponent {}
