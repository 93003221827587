import { Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';
import { isAuthenticatedGuard } from '@shared/guards/is-authenticated.guard';
import { uniqueKeyGuard } from '@shared/guards/unique-key.guard';
import { UserLoginComponent } from './modules/user/pages/user-login/user-login.component';
import { MainPageComponent } from './core/pages/main-page/main-page.component';
import { StatisticsState } from './modules/statistics/store/statistics.state';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { DocumentLayoutComponent } from './core/layouts/document-layout/document-layout.component';
import { DtImportState } from './modules/dt-import/store/dt-import.state';
import { DtsState } from './modules/dts/store/dts.state';
import { KdtImportState } from './modules/kdt-import/store/kdt-import.state';
import { ForbiddenPageComponent } from './core/pages/forbidden-page/forbidden-page.component';
import {
  OrganizationRouteNames,
  PaymentOrderRouteNames,
  RouteNames,
} from './route-names';
import { UserRegisterComponent } from './modules/user/pages/user-register/user-register.component';
import { UserResetPasswordComponent } from './modules/user/pages/user-reset-password/user-reset-password.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: RouteNames.MAIN_PAGE,
        component: MainPageComponent,
        title: 'Главная страница',
      },
      {
        path: RouteNames.LOGIN_PAGE,
        component: UserLoginComponent,
        title: 'Войти',
      },
      {
        path: RouteNames.REGISTER_PAGE,
        component: UserRegisterComponent,
        title: 'Регистрация',
        canActivate: [uniqueKeyGuard],
      },
      {
        path: RouteNames.RESET_PASSWORD_PAGE,
        component: UserResetPasswordComponent,
        title: 'Восстановление пароля',
      },
      {
        path: RouteNames.KANBAN_PAGE,
        title: 'Канбан',
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/kanban/routes').then(mod => mod.KANBAN_ROUTES),
      },
      {
        path: RouteNames.JOURNAL_PAGE,
        title: 'Журнал',
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/journal/routes').then(mod => mod.JOURNAL_ROUTES),
      },
      {
        path: RouteNames.NOMENCLATURE,
        title: 'ТНВЭД',
        loadComponent: () =>
          import(
            './modules/nomenclature/pages/nomenclature-page/nomenclature-page.component'
          ).then(c => c.NomenclaturePageComponent),
        canActivate: [isAuthenticatedGuard],
      },
      {
        path: RouteNames.USER,
        title: 'Учетная запись',
        loadComponent: () =>
          import('./modules/user/pages/user-edit/user-edit.component').then(
            c => c.UserEditComponent,
          ),
        canActivate: [isAuthenticatedGuard],
      },
      {
        path: OrganizationRouteNames.MAIN,
        title: 'Организация',
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/organization/routes').then(
            mod => mod.ORGANIZATION_ROUTES,
          ),
      },
      {
        path: PaymentOrderRouteNames.MAIN,
        title: 'Платежные поручения',
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/payment-order/routes').then(
            mod => mod.PAYMENT_ORDERS_ROUTES,
          ),
      },
      {
        path: RouteNames.CATALOGS_PAGE,
        title: 'Каталоги',
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/catalogs/routes').then(mod => mod.CATALOGS_ROUTES),
      },
      {
        path: RouteNames.FORBIDDEN_PAGE,
        title: 'Доступ запрещен',
        component: ForbiddenPageComponent,
      },
    ],
  },
  {
    path: '',
    component: DocumentLayoutComponent,
    children: [
      {
        path: RouteNames.STATISTICS_PAGE.concat('/:id'),
        title: 'СД',
        loadComponent: () =>
          import(
            './modules/statistics/pages/statistics-detail-page/statistics-detail-page.component'
          ).then(c => c.StatisticsDetailPageComponent),
        canActivate: [isAuthenticatedGuard],
        providers: [provideStates([StatisticsState])],
      },
      {
        path: RouteNames.DT_IMPORT_PAGE.concat('/:id'),
        title: 'ДТ',
        loadComponent: () =>
          import(
            './modules/dt-import/pages/dt-import-detail-page/dt-detail-page.component'
          ).then(c => c.DtDetailPageComponent),
        canActivate: [isAuthenticatedGuard],
        providers: [provideStates([DtImportState])],
      },
      {
        path: RouteNames.KDT_IMPORT_PAGE.concat('/:id'),
        title: 'КДТ',
        loadComponent: () =>
          import(
            './modules/kdt-import/pages/kdt-import-detail-page/kdt-detail-page.component'
          ).then(c => c.KdtDetailPageComponent),
        canActivate: [isAuthenticatedGuard],
        providers: [provideStates([KdtImportState])],
      },
      {
        path: RouteNames.DTS_PAGE.concat('/:id'),
        title: 'ДТС-1',
        loadComponent: () =>
          import(
            './modules/dts/pages/dts-detail-page/dts-detail-page.component'
          ).then(c => c.DtsDetailPageComponent),
        canActivate: [isAuthenticatedGuard],
        providers: [provideStates([DtsState])],
      },
      {
        path: RouteNames.DTS_2_PAGE.concat('/:id'),
        title: 'ДТС-2',
        loadComponent: () =>
          import(
            './modules/dts/pages/dts-2-detail-page/dts-2-detail-page.component'
          ).then(c => c.Dts2DetailPageComponent),
        canActivate: [isAuthenticatedGuard],
        providers: [provideStates([DtsState])],
      },
    ],
  },
  {
    path: '**',
    redirectTo: RouteNames.KANBAN_PAGE,
  },
];
