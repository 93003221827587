import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TuiButtonModule } from '@taiga-ui/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs';
import {
  downloadFile,
  getFilenameFromContentDisposition,
} from '@shared/utils/download-file';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { Environment } from '../../../../types/environment';

@Component({
  selector: 'app-download-sign-service',
  standalone: true,
  imports: [TuiButtonModule],
  templateUrl: './download-sign-service.component.html',
  styleUrl: './download-sign-service.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadSignServiceComponent {
  constructor(
    private readonly http: HttpClient,
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
  ) {}

  downloadSigner() {
    this.http
      .get(this.env.apiUrl.concat('/auth/user/downloadSigner'), {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(take(1))
      .subscribe(res => {
        if (res.body) {
          downloadFile({
            data: res.body,
            name: getFilenameFromContentDisposition(
              res.headers.get('content-disposition'),
            ),
          });
        }
      });
  }
}
