import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import {
  Antidumping,
  BankBIC,
  CatalogDetails,
  CatalogTypes,
  CatalogUpdates,
  CustomsDuty,
  Excise,
  IntellectualProperty,
  PreparedCatalog,
  TradeMarkCategoryByCode,
  TradeMarksCategory,
} from '../../types/catalog';
import {
  NomenclatureExtraItem,
  NormalizedExtraOptions,
} from '../../types/nomenclature';
import { Environment } from '../../types/environment';

@Injectable({
  providedIn: 'root',
})
export class CatalogsService {
  readonly API = `${this.env.apiUrl}/nsi`;

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
  ) {}

  getLastUpdated() {
    return this.http.get<CatalogUpdates>(`${this.API}/common/getLastUpdated`);
  }

  fetchCatalog(catalog: CatalogTypes) {
    return this.http.get<CatalogDetails<unknown>>(
      `${this.API}/${catalog}/getActual`,
    );
  }

  fetchExtraTnved({ code, actionDate }: { code: string; actionDate: string }) {
    return this.http.get<NomenclatureExtraItem[]>(
      `${this.API}/nsiAddTnVed/getTreeByTnVed?code=${code}&actionDate=${actionDate}`,
    );
  }

  fetchExtraTnvedOptions() {
    return this.http.get<NormalizedExtraOptions>(
      `${this.API}/nsiAddTnVed/getDistinctNames`,
    );
  }

  getExcisesByTnVed({
    code,
    actionDate,
  }: {
    code: string;
    actionDate: string;
  }) {
    return this.http.get<Excise[]>(
      `${this.API}/nsiTarif/getExcisesByTnVed?tnVed=${code}&declarationDate=${actionDate}`,
    );
  }

  getAntidumpingsByTnVed({
    code,
    actionDate,
  }: {
    code: string;
    actionDate: string;
  }) {
    return this.http.get<Antidumping[]>(
      `${this.API}/nsiTarif/getAntidumpingsByTnVd?tnVed=${code}&declarationDate=${actionDate}`,
    );
  }

  getCustomsDutiesByTnVed({
    code,
    actionDate,
  }: {
    code: string;
    actionDate: string;
  }) {
    return this.http.get<CustomsDuty[]>(
      `${this.API}/nsiTarif/getCustomsDutiesByTnVed?tnVed=${code}&declarationDate=${actionDate}`,
    );
  }

  getIntellectualPropertyByTnVed({ code }: { code: string }) {
    return this.http.get<IntellectualProperty[]>(
      `${this.API}/nsiIntellectualProperty/getByTnVed?tnVed=${code}`,
    );
  }

  getRatesOfFourDigitTnVedChildren({ code }: { code: string }) {
    return this.http.get<string[]>(
      `${this.API}/nsiTarif/getRatesOfFourDigitTnVedChildren?tnVed=${code}`,
    );
  }

  fetchBankBIC() {
    return fromPromise<PreparedCatalog<BankBIC>>(
      fetch('https://www.nbrb.by/api/bic')
        .then<BankBIC[]>(res => res.json())
        .then(items =>
          items.map(item => {
            return {
              ...item,
              search: `${item.CDBank} - ${item.NmBankShort}`,
            };
          }),
        ),
    );
  }

  getTradeMarks(categoryId: number) {
    return this.http.get<any[]>(
      `${this.API}/nsiTradeMark/getByListIdAndName?listId=${categoryId}`,
    );
  }

  getTradeMarksCategories() {
    return this.http.get<TradeMarksCategory[]>(
      `${this.API}/nsiAddTnVedCategory/getAll`,
    );
  }

  getTradeMarkCategoryByTnved(code: string) {
    return this.http.get<Nullable<TradeMarkCategoryByCode>>(
      `${this.API}/nsiAddTnVedCategory/getByTnVed?tnVed=${code}`,
    );
  }
}
